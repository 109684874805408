@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply font-sans;
}

body {
  --bgColor: #f1f5f9;

  @apply text-black;

  min-width: 320px;
  background: var(--bgColor);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.dark {
  --bgColor: #27272a;
}

@layer components {
  .h1 {
    @apply text-3xl font-bold leading-9 text-black dark:text-white;
  }

  .h2 {
    @apply text-2xl font-semibold leading-8 text-gray-900 dark:text-gray-50;
  }

  .h3 {
    @apply text-xl font-medium leading-7 text-gray-900 dark:text-gray-50;
  }

  .h4 {
    @apply text-lg font-medium leading-6 text-gray-900 dark:text-gray-50;
  }

  .h5 {
    @apply text-base font-semibold leading-5 text-gray-900 dark:text-gray-50;
  }

  .badge {
    @apply rounded-full px-3 py-1 text-sm;
  }

  .input {
    @apply rounded-md border border-gray-300 text-sm placeholder-gray-400 outline-none focus:border-ketoneBlue-600 focus:outline-none focus:ring-0;
  }

  .label {
    @apply text-sm font-medium leading-5 text-gray-500 dark:text-gray-300;
  }

  .label-secondary {
    @apply text-sm leading-5 text-gray-500 dark:text-gray-300;
  }

  .paragraph {
    @apply text-sm leading-5 text-gray-500 dark:text-gray-300;
  }

  .greyButton {
    @apply block rounded-lg bg-gray-50 p-3 text-center font-semibold text-gray-500 transition hover:bg-gray-100 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600;
  }

  .chevron-expander {
    @apply inline-flex h-5 w-5 rounded-lg border border-gray-300 md:h-6 md:w-6;
  }

  .trends-cards-grid {
    @apply grid gap-2 xs:grid-cols-2 sm:gap-4 md:flex lg:gap-8;
  }

  .bullet-list {
    @apply list-disc pl-4 text-gray-500 dark:text-gray-300;
  }

  .link {
    @apply block break-words text-gray-500 underline dark:text-gray-300;
  }

  .card {
    @apply rounded-2xl border border-transparent bg-white p-4 shadow-md dark:border dark:border-gray-600 dark:bg-gray-800 dark:shadow-none md:p-8;
  }

  .scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    margin-left: 5px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    @apply bg-gray-100 dark:bg-gray-800;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    border-radius: 100vh;
    @apply border-4 border-gray-300 bg-gray-300 dark:border-gray-800 dark:bg-gray-400;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
    @apply bg-gray-400 dark:bg-gray-500;
  }
}

@layer base {
  /* disable arrows on input[type="number"] */
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer utilities {
  /*  input[type='number'] */
  .hide-spin-button::-webkit-inner-spin-button,
  .hide-spin-button::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  /* input[type='time'] */
  input[type='time']::-webkit-calendar-picker-indicator,
  input[type='datetime-local']::-webkit-calendar-picker-indicator {
    appearance: none;
    display: none;
    background: none;
  }

  /* Custom Scroll */
  .custom-scroll::-webkit-scrollbar {
    @apply w-1.5;
  }

  .custom-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px theme('colors.gkiGrey.200');
  }

  .custom-scroll::-webkit-scrollbar-thumb {
    @apply rounded-none bg-gkiGrey-600;
  }
}

/* Hide input[numbers controls ] on Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide input[numbers controls ] on Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.gauge-chart-gradient {
  fill: url(#mainGradient) !important;
}

.select__base-bg {
  @apply bg-white dark:bg-coolGray-600;
}

.select__option-text {
  @apply text-gray-900 dark:text-gkiGrey-300;
}

.select__option-bg {
  @apply bg-white transition hover:bg-gray-50 dark:bg-coolGray-600 dark:hover:bg-coolGray-800;
}

.select__option-bg--active {
  @apply bg-gray-50 dark:bg-coolGray-800;
}

.select__option-bg--selected {
  @apply bg-gray-100 dark:bg-coolGray-900;
}

.select__option-bg--focus {
  @apply focus:bg-gray-100 dark:focus:bg-coolGray-900;
}

@media only percy {
  .percy-hidden {
    visibility: hidden;
  }
}

.tableHeaderSeparator {
  position: relative;
}
.tableHeaderSeparator::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 20px;
  width: 2px;
  background-color: theme('colors.gray.300');
}

:global(.dark) .tableHeaderSeparator::after {
  background-color: theme('colors.gray.100');
}
