.wrapper {
  font-size: 0.75rem;
  font-weight: theme('fontWeight.medium');
  line-height: 1.1em;
  color: theme('colors.gray.500');
  margin: 0;
  padding: 0;
}

:global(.dark) {
  .wrapper {
    color: theme('colors.gray.300');
  }
}
