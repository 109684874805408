.wrapper {
  background-color: white;
  border-radius: 10px;
  border: 1px solid theme('colors.gray.300');
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
  padding: 6px;
  overflow: hidden;
  max-height: 15rem;
  overflow-y: auto;

  :global(.dark) & {
    background-color: theme('colors.gray.800');
    box-shadow: 0 3px 9px rgba(255, 255, 255, 0.1);
    border: 1px solid theme('colors.gray.600');
  }
}
