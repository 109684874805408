@import '../../../../../../components/forms-v2/shared/forms.styles.scss';

@mixin focusStyles() {
  outline: none;
  background-color: theme('colors.ketoneBlue.50');
  color: theme('colors.ketoneBlue.DEFAULT');
  border: 1px solid theme('colors.ketoneBlue.DEFAULT');

  :global(.dark) & {
    background-color: theme('colors.ketoneBlue.900');
    border: 1px solid theme('colors.ketoneBlue.400');
    color: theme('colors.ketoneBlue.400');
  }
}

.menu {
  position: relative;
  display: inline-block;
}

.menu[data-headlessui-state='open'] {
  z-index: 20;
}

.fullScreenModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: theme('colors.white');
  z-index: 900;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.closeMenuBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  padding: 15px;
  color: theme('colors.coolGray.500');
  border-radius: 8px 8px 0 0;
  font-size: 0.875em;
  margin-left: auto;

  :global(.dark) & {
    color: theme('colors.coolGray.200');
  }
}

.option {
  @include textStyles;

  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  color: theme('colors.gray.500');
  text-align: left;

  :global(.dark) & {
    background-color: theme('colors.gray.800');
    color: theme('colors.gray.300');
  }

  &:hover {
    background-color: theme('colors.ketoneBlue.50');
    color: theme('colors.ketoneBlue.DEFAULT');

    :global(.dark) & {
      background-color: theme('colors.ketoneBlue.900');
      color: theme('colors.ketoneBlue.400');
    }
  }

  &:focus,
  &.active {
    @include focusStyles;
  }

  & svg {
    min-width: 22px;
    min-height: 22px;
    color: inherit;

    :global(.dark) & {
      color: inherit;
    }
  }
}

.mobileItems {
  height: 100vh;
  padding: 6px;
  overflow-y: auto;
  background-color: white;

  :global(.dark) & {
    background-color: theme('colors.gray.800');
  }
}

.items {
  position: absolute;
  min-width: 200px;
  right: 0;
  margin-top: 0.5rem;
  width: 100%;
  transform-origin: top right;
  background-color: white;
  border-radius: 10px;
  border: 1px solid theme('colors.gray.300');
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
  padding: 6px;
  overflow: hidden;
  max-height: 15rem;
  overflow-y: auto;
  z-index: 20;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    background-color: theme('colors.gray.300');
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: theme('colors.gray.400');
  }

  :global(.dark) & {
    background-color: theme('colors.gray.800');
    box-shadow: 0 3px 9px rgba(255, 255, 255, 0.1);
    border: 1px solid theme('colors.gray.600');

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      background-color: theme('colors.gray.700');
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: theme('colors.gray.900');
    }
  }
}

.categoryHeading {
  font-weight: theme('fontWeight.semibold');
  font-size: theme('fontSize.xs');
  color: theme('colors.gray.500');
  background-color: theme('colors.gray.100');
  line-height: 1em;
  text-align: left;
  padding: 10px;
  margin-left: -6px;
  margin-right: -6px;
  margin-bottom: 6px;

  &:not(:first-of-type) {
    margin-top: 6px;
  }

  &:first-of-type {
    margin-top: -6px;
  }

  :global(.dark) & {
    background-color: theme('colors.gray.700');
    color: theme('colors.gray.300');
  }
}
