.animatedToast {
  animation: slideInFromLeft 0.5s ease;
}

.contentWrapper {
  word-break: break-word;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}
