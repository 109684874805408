@import '../../forms.styles.scss';

.button {
  @include input;

  display: inline-grid;
  text-align: left;
  position: relative;
  padding: 10px;

  &:focus:not(.isDisabled):not(.isInvalid) .arrowsWrapper svg {
    color: theme('colors.ketoneBlue.DEFAULT');
  }

  &.isInvalid .arrowsWrapper svg {
    color: theme('colors.red.500');
  }
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.arrowsWrapper {
  width: 22px;
  height: 22px;

  & svg {
    color: theme('colors.gray.400');
    width: 100%;
    height: 100%;
  }
}

.iconWrapper {
  @include inputIconWrapper;
}

.count {
  display: inline-block;
  width: 22px;
  height: 16px;
  padding: 2px 4px;
  color: white;
  background-color: theme('colors.ketoneBlue.DEFAULT');
  border-radius: 4px;
  font-size: 0.75rem;
  line-height: 1em;
  font-weight: 500;
  text-align: center;

  :global(.dark) & {
    background-color: theme('colors.ketoneBlue.400');
    color: theme('colors.gray.800');
  }
}

.isDisabled .count {
  background-color: theme('colors.gray.400');
  color: theme('colors.gray.100');
}

.content {
  min-height: 17px;
  display: inline-block;
  line-height: 18px;
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
