@import '../../../../../../components/forms-v2/shared/forms.styles.scss';

.button {
  @include input;

  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 10px;
}

.arrowsWrapper {
  width: 22px;
  height: 22px;

  & svg {
    color: theme('colors.gray.400');
    width: 100%;
    height: 100%;
  }
}

.content {
  min-height: 17px;
  display: inline-block;
  line-height: 18px;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
