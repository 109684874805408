.text {
  display: flex;
  font-size: 0.75rem;
  font-weight: theme('fontWeight.medium');
  line-height: 1em;
  color: theme('colors.gray.600');
}

.tooltipIcon {
  color: theme('colors.gray.600');
  height: 0.75rem;
  width: 0.75rem;
  margin-left: 5px;
}

:global(.dark) {
  .text {
    color: theme('colors.gray.300');
  }

  .tooltipIcon {
    color: theme('colors.gray.300');
  }
}
