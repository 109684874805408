@mixin textStyles() {
  font-size: 1rem;
  line-height: 1em;
  font-weight: theme('fontWeight.normal');
}

@mixin inputBase() {
  width: 100%;
  padding: 9px 10px;
  border: 1px solid theme('colors.gray.300');
  border-radius: 10px;
  color: theme('colors.gray.700');
  background-color: theme('colors.white');

  &::placeholder,
  .placeholder {
    @include textStyles;

    color: theme('colors.gray.500');

    :global(.dark) & {
      color: theme('colors.gray.400');
    }
  }
}

@mixin label() {
  padding: 0 10px;
  margin-bottom: 6px;
  text-align: left;
}

@mixin hint() {
  padding: 0 10px;
  text-align: left;
  display: block;
  font-size: 0.75rem;
  font-weight: theme('fontWeight.light');
  line-height: 1em;
  color: theme('colors.gray.500');
  margin-bottom: 6px;

  :global(.dark) & {
    color: theme('colors.gray.200');
  }
}

@mixin errorMessage() {
  padding: 0 10px;
  margin-top: 4px;
}

@mixin input() {
  @include textStyles;
  @include inputBase;

  transition: border-color 0.15s ease, box-shadow 0.15s ease;

  :global(.dark) & {
    background-color: theme('colors.gray.800');
    border-color: theme('colors.gray.600');
    color: theme('colors.gray.300');
  }

  &.isWithIcon {
    padding-left: 34px;
  }

  @include hoverState();
  @include focusState();
  @include invalidState();
  @include disabledState();

  &[type='file'] {
    height: 40px;
    line-height: 0;
  }
  &::file-selector-button {
    appearance: button;
    cursor: pointer;
    @apply mr-2 cursor-pointer appearance-none rounded-md px-4 align-middle text-xs font-semibold;
    @apply border border-solid border-ketoneBlue bg-ketoneBlue-50 transition hover:bg-ketoneBlue-100;
    @apply disabled:cursor-not-allowed disabled:bg-ketoneBlue-50 disabled:opacity-50;
    @apply text-ketoneBlue;
    :global(.dark) & {
      @apply border-ketoneBlue-400 bg-ketoneBlue-900 hover:bg-ketoneBlue-800;
      @apply disabled:bg-ketoneBlue-900;
      @apply text-ketoneBlue-400;
    }
  }
}

@mixin focusState() {
  &:focus:not(.isDisabled):not(:disabled),
  &:focus-within:not(.isDisabled):not(:disabled):not(.isInvalid) {
    outline: none;
    border-color: theme('colors.ketoneBlue.DEFAULT');
    box-shadow: 0 0 0 4px rgba(16, 179, 207, 0.2);

    :global(.dark) & {
      border-color: theme('colors.ketoneBlue.400');
      box-shadow: 0 0 0 4px rgba(85, 219, 242, 0.4);
    }
  }
}

@mixin invalidState {
  &.isInvalid:not(.isDisabled):not(:disabled) {
    border-color: theme('colors.red.500');

    &:focus,
    &:focus-within {
      border: 1px solid theme('colors.red.500') !important;
      box-shadow: 0 0 0 4px rgba(239, 68, 68, 0.2) !important;
    }
  }
}

@mixin hoverState {
  &:hover {
    border-color: theme('colors.ketoneBlue.DEFAULT');

    &.isInvalid:not(.isDisabled):not(:disabled) {
      border-color: theme('colors.red.500') !important;
    }

    :global(.dark) & {
      border-color: theme('colors.ketoneBlue.400');
    }
  }
}

@mixin disabledState() {
  &:disabled,
  &.isReadonly,
  &.isDisabled {
    border-color: theme('colors.gray.300');
    background-color: theme('colors.gray.100');
    color: theme('colors.gray.400');
    cursor: not-allowed;

    &:hover {
      background-color: theme('colors.gray.100');
    }

    :global(.dark) & {
      border-color: theme('colors.gray.600');
      background-color: theme('colors.gray.700');

      &:hover {
        background-color: theme('colors.gray.700');
      }
    }
  }
}

@mixin inputIconWrapper() {
  min-width: 22px;
  min-height: 22px;
  color: theme('colors.gray.400');

  :global(.dark) & {
    color: theme('colors.gray.400');
  }
}

@mixin checkboxStyles() {
  position: relative;
  display: flex;
  align-items: center;

  .checkbox {
    height: 16px;
    width: 16px;
    background-color: theme('colors.white');
    border: 1px solid theme('colors.gray.300');
    cursor: pointer;

    &:checked,
    &:focus:checked {
      background-color: theme('colors.ketoneBlue.DEFAULT');
      border: 1px solid theme('colors.ketoneBlue.DEFAULT');
    }

    &:hover:checked {
      border: 1px solid theme('colors.ketoneBlue.400');
      background-color: theme('colors.ketoneBlue.DEFAULT');
    }

    &:hover:not(:checked) {
      border: 1px solid theme('colors.ketoneBlue.DEFAULT');
      background-color: theme('colors.ketoneBlue.100');
    }

    &:hover:disabled {
      border: 1px solid theme('colors.gray.300');
    }

    &.isInvalid:checked:not(:disabled) {
      background-color: theme('colors.red.500');
    }

    &:disabled:checked {
      @content ('lightgray');
    }

    :global(.dark) & {
      border: 1px solid theme('colors.gray.600');
      background-color: theme('colors.gray.800');

      &:hover:checked {
        border: 1px solid theme('colors.ketoneBlue.300');
      }

      &:hover:not(:checked) {
        background-color: theme('colors.gray.800');
        border: 1px solid theme('colors.ketoneBlue.DEFAULT');
      }

      &:hover:disabled {
        border: 1px solid theme('colors.gray.600');
      }

      &:checked {
        background-color: theme('colors.ketoneBlue.400');
        border: 1px solid theme('colors.ketoneBlue.400');

        @content ('27272A');
      }

      &.isInvalid:checked:not(:disabled) {
        background-color: theme('colors.red.500');
      }

      &:disabled {
        background-color: theme('colors.gray.700');
      }

      &:disabled:checked {
        background-color: theme('colors.gray.700');

        @content ('gray');
      }
    }

    @include focusState();
    @include invalidState();
    @include disabledState();
  }

  .checkboxLabel {
    font-size: 0.75rem;
    font-weight: theme('fontWeight.medium');
    display: flex;
    align-items: center;
    gap: 6px;
    color: theme('colors.gray.600');

    :global(.dark) & {
      color: theme('colors.gray.300');
    }
  }
}
