@import '../../forms.styles.scss';

@mixin focusStyles() {
  outline: none;
  background-color: theme('colors.ketoneBlue.50');
  color: theme('colors.ketoneBlue.DEFAULT');
  border: 1px solid theme('colors.ketoneBlue.DEFAULT');

  :global(.dark) & {
    background-color: theme('colors.ketoneBlue.900');
    border: 1px solid theme('colors.ketoneBlue.400');
    color: theme('colors.ketoneBlue.400');
  }
}

.option {
  @include textStyles;

  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  color: theme('colors.gray.500');

  :global(.dark) & {
    background-color: theme('colors.gray.800');
    color: theme('colors.gray.300');
  }

  &:hover {
    background-color: theme('colors.ketoneBlue.50');
    color: theme('colors.ketoneBlue.DEFAULT');

    :global(.dark) & {
      background-color: theme('colors.ketoneBlue.900');
      color: theme('colors.ketoneBlue.400');
    }
  }

  &.selected {
    background-color: theme('colors.ketoneBlue.DEFAULT');
    color: white;

    svg {
      color: white;
    }

    :global(.dark) & {
      background-color: theme('colors.ketoneBlue.400');
      color: theme('colors.gray.800');

      svg {
        color: theme('colors.gray.800');
      }
    }
  }

  &:focus:not(.selected) {
    @include focusStyles;
  }
}

:focus > .option:not(.selected) {
  @include focusStyles;
}

.iconWrapper {
  @include inputIconWrapper;

  color: inherit;

  :global(.dark) & {
    color: inherit;
  }
}
