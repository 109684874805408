@import '../../forms.styles.scss';

.search {
  @include inputBase;

  position: relative;
  padding-left: 34px;

  :global(.dark) & {
    background-color: theme('colors.gray.800');
    border-color: theme('colors.gray.600');
    color: theme('colors.gray.300');
  }

  &:focus {
    outline: none;
    border-color: theme('colors.ketoneBlue.DEFAULT');
    box-shadow: 0 0 0 4px rgba(16, 179, 207, 0.2);

    :global(.dark) & {
      border-color: theme('colors.ketoneBlue.400');
      box-shadow: 0 0 0 4px rgba(85, 219, 242, 0.4);
    }
  }
}

.iconWrapper {
  @include inputIconWrapper;

  position: absolute;
  bottom: 9px;
  left: 8px;
}

.categoryHeading {
  font-weight: theme('fontWeight.semibold');
  font-size: theme('fontSize.xs');
  color: theme('colors.gray.500');
  background-color: theme('colors.gray.100');
  line-height: 1em;
  text-align: left;
  padding: 10px;
  margin-left: -6px;
  margin-right: -6px;
  margin-bottom: 6px;

  &:not(:first-of-type) {
    margin-top: 6px;
  }

  &:first-of-type {
    margin-top: -6px;
  }

  :global(.dark) & {
    background-color: theme('colors.gray.700');
    color: theme('colors.gray.300');
  }
}

.optionWrapper {
  &:focus {
    outline: none;
  }

  &.isDisabled {
    opacity: 0.5;
  }
}

.noOptionLabel {
  font-size: theme('fontSize.sm');
  line-height: 1rem;
  color: theme('colors.gray.500');
  padding: 10px;

  :global(.dark) & {
    color: theme('colors.gray.300');
  }
}
