@import '../../shared/forms.styles.scss';

.label {
  @include label;
}

.hint {
  @include hint;
}

.errorMessage {
  @include errorMessage;
}
