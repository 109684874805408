.Combobox__suggestions ul {
  @apply max-h-60 w-full overflow-y-auto bg-white dark:bg-coolGray-600;
}

.Combobox__suggestions li {
  @apply cursor-default select-none bg-white
    py-2 pl-3
    pr-4 text-gray-900
    transition hover:bg-gray-50 dark:bg-coolGray-600 dark:text-gkiGrey-300 dark:hover:bg-coolGray-800;
}
.Combobox__suggestions li:not(:last-child) {
  @apply border-b border-gray-100 dark:border-gray-700;
}

.Combobox__activeSuggestion {
  @apply bg-gray-50 dark:bg-coolGray-800;
}
