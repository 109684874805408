.pdf-report {
  max-width: 210mm;
  background: white;

  .pdf-a4-page {
    height: 295mm;
    padding: 25px;
    overflow: hidden;
  }

  .card {
    @apply border-none;
  }

  // Tailwind Overwrites

  .ml-2 {
    margin-left: 0 !important;
  }

  .shadow-md {
    box-shadow: none !important;
  }

  .container {
    max-width: 100% !important;
  }

  // Utilities for hiding elements

  .pdf-invisible {
    visibility: hidden !important;
  }

  .pdf-hidden {
    display: none !important;
  }

  // Components overrides

  .gauge {
    display: flex;
    justify-content: center;
  }

  .range-reading {
    @apply m-0 mb-2;
  }

  .pdf-metrics-tile {
    @apply rounded-xl border border-gray-200 p-4;
  }

  .pdf-metrics-tile__stat-type-trends__label {
    @apply m-0;
  }

  .legend {
    @apply flex flex-row flex-wrap items-start gap-3 space-y-0;
  }

  .legend-item {
    @apply m-0;
  }

  .legend-text {
    @apply whitespace-nowrap;

    font-size: 10px;
  }

  .card {
    @apply bg-white;
  }

  .full-page-chart {
    .pie-chart-wrapper,
    .stacked-bar-chart-wrapper,
    .multiline-chart-wrapper {
      height: 150mm;
      width: 200mm;
    }
  }
}
