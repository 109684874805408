.heading {
  @apply mb-4 text-sm font-bold uppercase text-gray-500;
}

.link {
  @apply mb-4 text-base font-normal cursor-pointer leading-6 text-gray-500 last:mb-0 hover:underline;
}

:global(.dark) {
  .heading {
    @apply text-gray-300;
  }

  .link {
    @apply text-gray-200;
  }
}
